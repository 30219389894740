import React   from 'react';
import "mapbox-gl/dist/mapbox-gl.css";
import "video-react/dist/video-react.css";
import { Player, ReplayControl,ForwardControl, ControlBar,PlaybackRateMenuButton,  } from 'video-react';

import Axios from './../Axios';
import {Card,Empty, Drawer,Spin, message, Button, Row, Col,Descriptions} from 'antd';
import moment  from 'moment'

import { ArrowLeftOutlined } from '@ant-design/icons';

import VideoStatus from './../Common/VideoStatus'
import Fined24Alert from './../Common/Fined24Alert'
import VideoRevert from './../Common/VideoRevert'
import SnapButton from '../Utils/SnapButton';
import SelectAjax from '../Utils/SelectAjax';

import {useState, useEffect} from 'react'
// import PlaybackRateMenuButton from 'video-react/lib/components/control-bar/PlaybackRateMenuButton';

const DATE_FORMAT = 'DD/MM/YYYY hh:mm a';
const VideoDetail = ({match}) => {

   const [snapLoading] = useState(false);
   const [video, setVideo] = useState(null);
   const [showForm] = useState(false);

   const fetchVideo  = async () => {
      const response = await Axios.get('/videos/'+match.params.id);

      setVideo(response.data.data);
   }

   const updateSnaps = (data) => {

     const vid = {...video}
     vid.snaps = data;
     this.setState({video:vid, snapLoading: false});
   }

   const back = () => {
    window.history.back();
   }

   useEffect(() => {

     fetchVideo();

   },[]);

   const downloadPDF = (id) => {

    const hide = message.loading('Computando PDF...', 0);

    Axios.get('/fines/'+id + '/pdf', {responseType: 'blob'}).then(response => {
         let blob = new Blob([response.data], {type: 'application/pdf'});

         hide();

         let url = URL.createObjectURL(blob)

         let a = document.createElement('a');
          a.href = url;
          // a.target = '_blank';
          // a.download = 'papeleta'+ id + '.pdf';
          // a.click();

         window.open(url);

     }).catch(() => {
        hide();
        message.error("Error al generar documento");
     })

  }

   return <Card title={<span> <Button onClick={()=>back()}  type="link" icon={<ArrowLeftOutlined />}/> {"Detalle de video #"+match.params.id }</span>} extra={ (video && video.fine )? <VideoRevert id={video.fine.id} onSuccess={()=>fetchVideo()}/> : null} className="card-full">

     { video!=null   ?  <Row>

       <Col md={10}>
         <Player
           crossOrigin="anonymous"
           autoPlay={false}
           fluid={false} width={'100%'} height={500}
           playsInline
           poster={video.cover_url_cdn}
           src={video.video_url_cdn}
         >
           <ControlBar autoHide={false}>

                <ReplayControl seconds={5} order={6.1} />
              <ForwardControl seconds={5} order={6.2} />
              <PlaybackRateMenuButton rates={[3, 2, 1, 1.5, 0.5, 0.1]} order={6.3} />

             <SnapButton onCapturing={()=>this.setState({snapLoading: true})} onSuccess={(data)=>updateSnaps(data)} id={video.id} order={7} />
           </ControlBar>
         </Player>

         <Fined24Alert videoId={video.id}/>

         <iframe
           title="map"
           src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2966.8292019522214!2d"+video.lng_video+"!3d"+video.lng_video+"!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTLCsDA1JzUyLjEiUyA3NsKwNTYnNTcuOCJX!5e0!3m2!1ses-419!2spe!4v1585545089667!5m2!1ses-419!2spe"}
           width="100%"
           height="600"
           style={{width: '100wh'}}
         frameBorder="0" allowFullScreen aria-hidden="false" ></iframe>

         <Drawer
           onClose={()=>this.setState({showForm: false})}
           visible={showForm}
           title="Atender video"
           width={(window.innerWidth/2 )+ 250}
           closable={true}
           maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.74)'}}
         >
           {/* <Form2 onSuccess={this.finishForm} video={video}/> */}
         </Drawer>

       </Col>

       <Col md={14}>

         <div style={{padding: '10px 20px'}}>

           <Row gutter={16}>
             <Col span={8}>
               <Card title="Placa/DNI" bordered={false}>
                 <span className="video-detail-big">
                   {video.vehicle_plate}</span>
               </Card>
             </Col>
             <Col span={8}>
               <Card title="Estado" bordered={false}>
                 <VideoStatus status={video.status} statusName={video.status_name}/>
               </Card>
             </Col>
             <Col span={8}>
               <Card title="Multa" bordered={false}>
                 <span className="video-detail-big">
                   {video.fine ? video.fine.infringement_code : '-'}
                 </span>

               </Card>
             </Col>
           </Row>

           <Descriptions
             bordered
             column={{ sm:  1 }}
           >

             <Descriptions.Item label="Estado">{video.status == 5 ? 'Multado': 'Atendido'}</Descriptions.Item>
             <Descriptions.Item label="Tipo"> {video.fine ? video.fine.comments : ''}</Descriptions.Item>
             <Descriptions.Item label="Correlativo">  { video.fine && <a onClick={()=>downloadPDF(video.fine.id)}>{video.fine.correlative_gtu}</a>  } </Descriptions.Item>
             <Descriptions.Item label="Atendido por">{video.mediator_name}</Descriptions.Item>
             <Descriptions.Item label="Reportador(Inspector)">{video.inspector_name ? video.inspector_name : '-'}</Descriptions.Item>
             <Descriptions.Item label="Fecha Atencion">{video.attended_date ? moment(video.attended_date).format(DATE_FORMAT) : '-'}</Descriptions.Item>
            
             <Descriptions.Item label="Fecha Grabación">{moment(video.device_creation_date).format(DATE_FORMAT)}</Descriptions.Item>
             <Descriptions.Item label="Fecha de Subida">{moment(video.created_at).format(DATE_FORMAT)}</Descriptions.Item>
             <Descriptions.Item label="Distrito/Lugar">

               <SelectAjax
                 disabled
                 label={'name'}   style={{width: '100%'}} value={video.municipality_id} url={'/utils/enabled-places'} placeholder="Asignar Municipalidad" />

             </Descriptions.Item>
             <Descriptions.Item label="Tipo de incidente">{video.infringement}</Descriptions.Item>

             <Descriptions.Item label="Dirección">{video.address}</Descriptions.Item>
             <Descriptions.Item label="GPS"><a target="blank" rel="noreferrer" href={"https://www.google.com/maps/search/?api=1&query="+video.lat_video+','+video.lng_video}>{video.lat_video},{video.lng_video}</a></Descriptions.Item>

             <Descriptions.Item label="Subido desde">{video.upload_origin + ' de ' + video.upload_from}</Descriptions.Item>

             { video.comments &&  <Descriptions.Item label="Comentarios">
               {video.comments}
             </Descriptions.Item>}

             {/* {video.penalties.length > 0 && <Descriptions.Item label="Detalles de la atención">{video.penalties.map((a,i)=>{
               return <div key={i}>Tipo: {a.infringement_code}<br/>Comentario: {a.comments}</div>
             })}</Descriptions.Item>} */}

             <Descriptions.Item label="Fotos">
               <Spin spinning={snapLoading}>
                 {video.snaps.length > 0 ?
                   <div style={{display: 'flex'}}>
                     {video.snaps.map((snap, key) => {
                       return <div key={key} className="img-snap"> <img src={snap.url_cdn}/></div>
                     })}
                   </div>

                 : <Empty  description={"No hay fotos"}/>}
               </Spin>
             </Descriptions.Item>

           </Descriptions>

         </div>

       </Col>

     </Row>  : <div>Cargando detalles... <Spin spinning></Spin></div>}

   </Card>

}

export default VideoDetail;
