import React from "react";
import "video-react/dist/video-react.css";
import { Link } from "react-router-dom";
import { Session } from "bc-react-session";
import Axios from "./../Axios";
import moment  from 'moment'
import {
  DownloadOutlined,
  FilePdfOutlined,
  ArrowRightOutlined,
  RiseOutlined,
  SoundOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { message, Table, Button, DatePicker, Input } from "antd";

import VideoRevert from "./../Common/VideoRevert";
import SelectAjax from "../Utils/SelectAjax";

const { RangePicker } = DatePicker;

const { payload } = Session.get();
const DATE_FORMAT = 'DD/MM/YYYY hh:mm a';
class Videos extends React.Component {
  state = {
    ref: React.createRef(),
    range: React.createRef(),
    video: null,
    revertLoading: false,
    isLoading: true,
    pagination: { total: 0 },
    fineList: [],
    filters: [],
  };

  customFetch = async (params = {}) => {
    console.log("params:", params);
    this.setState({ isLoading: true });
    const { pagination } = this.state;

    const response = await Axios.get("/fines", {
      params: {
        results: 10,
        ...params,
      },
    });
    const pager = pagination;
    pager.total = response.data.meta.total;

    this.setState({
      pagination: pager,
      fineList: response.data.data,
      isLoading: false,
    });
  };

  componentDidMount = () => {
    this.customFetch();

    console.log(payload);
  };

  filterField = (field, value) => {
    const { filters } = this.state;
    filters[field] = value;

    this.setState({ filters }, () => {
      this.customFetch(filters);
    });
  };

  changeDate = (value) => {
    if (!value) {
      const { filters } = this.state;
      delete filters["daterange"];

      this.setState({ filters }, () => {
        this.customFetch(filters);
      });

      return;
    }

    const dates = [
      value[0].format("YYYY-MM-DD"),
      value[1].format("YYYY-MM-DD"),
    ];

    this.filterField("daterange", dates);
    this.setState({ daterangemoment: value });
  };
  resetFilters = () => {
    this.setState({ filters: {} }, () => {
      this.customFetch({});
    });

    this.setState({ daterangemoment: null });
  };

  download = () => {
    const { filters } = this.state;

    const hide = message.loading(
      "Estamos descargando tu reporte, puede tardar unos minutos...",
      0
    );

    Axios.get("/fines", {
      responseType: "blob",
      params: { export: true, ...filters },
    })
      .then((response) => {
        let blob = new Blob([response.data]);

        hide();

        let url = URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = "reporte.xlsx";
        a.click();
      })
      .catch(() => {
        hide();
        message.error("Error al exportar documento");
      });
  };

  downloadPDF = (id) => {
    const hide = message.loading("Computando PDF...", 0);

    Axios.get("/fines/" + id + "/pdf", { responseType: "blob" })
      .then((response) => {
        let blob = new Blob([response.data], { type: "application/pdf" });

        hide();

        let url = URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        // a.target = '_blank';
        // a.download = 'papeleta'+ id + '.pdf';
        // a.click();

        window.open(url);
      })
      .catch(() => {
        hide();
        message.error("Error al generar documento");
      });
  };

  render() {
    const { fineList, pagination, isLoading, filters } = this.state;

    const columns = [
      {
        title: "# Multa",
        dataIndex: "correlative_gtu",
      },
      {
        title: "ID Video",
        dataIndex: "video_id",
      },
      {
        title: "Tipo",
        dataIndex: "status",
        render: (value) => (
          <span>
            {value === "attended" ? (
              <SoundOutlined style={{ color: "#00bcd4" }} />
            ) : (
              <DollarOutlined style={{ color: "#1edc26" }} />
            )}{" "}
            {value == "penalized" ? "Multado" : "Atendido"}{" "}
          </span>
        ),
      },
      {
        title: "Fecha atención",
        dataIndex: "date",
        render: (value, record) => (
          <Link to={"/videos/" + record.video_id}>{ moment(value).format(DATE_FORMAT)}</Link>
        ),
      },
      {
        title: "Fecha incidente",
        dataIndex: "date_incident",
        render:  (value, record) => moment(record.date_incident).format(DATE_FORMAT) 
      },
      {
        title: "Placa",
        dataIndex: "infringer_plate",
        render: (value) => <Link to={"/videos?search=" + value}>{value}</Link>,
      },
      {
        title: "Código",
        dataIndex: "infringement_code",
      },

      // {
      //   title: "Monto",
      //   key: "amount",
      //   dataIndex: "amount",
      //   align: "right",
      // },

      {
        title: "Distrito",
        dataIndex: "district",
      },
      {
        title: "PDF",
        key: "pdf_url",
        render: (a, item) => (
          <Button
            type="ghost"
            onClick={() => this.downloadPDF(item.id)}
            icon={<FilePdfOutlined />}
          ></Button>
        ),
      },
      payload.role === "admin"
        ? {
            title: "Revertir",
            dataIndex: "id",
            render: (value) => (
              <VideoRevert id={value} onSuccess={() => this.customFetch()} />
            ),
          }
        : {},

      {
        title: "Detalle",
        key: "detail",
        dataIndex: "video_id",
        align: "right",
        render: (value) => (
          <Link to={"/videos/" + value}>
            {" "}
            <Button type="link" icon={<ArrowRightOutlined />}>
              Detalles
            </Button>
          </Link>
        ),
      },
    ];

    const handleTableChange = (pagination, filters, sorter) => {
      const pager = pagination;
      pager.current = pagination.current;

      this.customFetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    };

    return (
      <>
        <h1 className="section-title">
          <RiseOutlined /> Reportes atendidos
        </h1>

        <div className="filters">
          <Input onChange={(e)=>this.filterField("search",e.target.value)} placeholder="# Multa o Placa" style={{width: 140}}/>
          <RangePicker
            value={
              this.state.daterangemoment ? this.state.daterangemoment : null
            }
            onChange={(value) => this.changeDate(value)}
          />

          <a style={{ display: "none" }} href="empty" ref={this.state.ref}>
            ref
          </a>

          <SelectAjax
            value={filters.municipality}
            style={{ width: 200, textAlign: "left" }}
            onChange={(value) => this.filterField("municipality", value)}
            label={"name"}
            url={"/utils/enabled-places"}
            placeholder="Todos los lugares"
          />

          <Button
            type="dashed"
            danger
            style={{ marginLeft: 10 }}
            onClick={this.resetFilters}
          >
            Resetear
          </Button>

          <div style={{ display: "inline" }}>
            <Button
              type="primary"
              onClick={this.download}
              icon={<DownloadOutlined />}
            >
              Exportar actual
            </Button>
          </div>
        </div>

        <Table
          scroll={{ x: "max-content" }}
          rowClassName={(record) => (record.locked == true ? "tr-locked" : "")}
          rowKey={"id"}
          columns={columns}
          dataSource={fineList}
          loading={isLoading}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </>
    );
  }
}

export default Videos;
