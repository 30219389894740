
import React from "react";
import { Tag } from "antd";

const StatusTag = ({ status }) => {
  const getStatusInfo = (status) => {
    switch (status) {
      case 5:
        return { name: "Multado", color: "red" };
      case 2:
        return { name: "Atendido", color: "green" };
      case 1:
        return { name: "Reportado", color: "blue" };
      case 0:
        return { name: "En moderación", color: "orange" };
      case 4:
        return { name: "Eliminado", color: "volcano" };
      default:
        return { name: "Otro", color: "gray" };
    }
  };

  const { name, color } = getStatusInfo(status);

  return <Tag color={color}>{name}</Tag>;
};

export default StatusTag;