import React, { useState, useEffect, useCallback } from "react";
import Axios, { setAuthHeader } from "./../Axios";
import Cropper from "react-easy-crop";
import {
  Slider,
  Empty,
  Form,
  Input,
  Tooltip,
  Select,
  Row,
  Radio,
  Col,
  Checkbox,
  Button,
  message,
  Spin,
  Modal,
  Divider,
  Cascader,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Session } from "bc-react-session";
import { getCroppedImg } from "./../Utils/cropImage";
import SelectAjax from "../Utils/SelectAjax";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { payload } = Session.get();

const InfringerForm = ({video}) => {

  return (
    <React.Fragment>
      <Divider>Información del infractor</Divider>

      <Form.Item
        name="infringer_document_type"
        label="Tipo de documento"
        rules={[{ required: false }]}
      >
        <Select>
          <Option value="1">RUC</Option>
          <Option value="2">DNI</Option>
          <Option value="3">Carnet de Identidad</Option>
          <Option value="4">Carnet Extranjería</Option>
          <Option value="5">Pasaporte</Option>
          <Option value="6">Otros</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="infringer_document_number"
        label="Nº de documento"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="infringer_fullname"
        label="Nombres"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="infringer_firstname"
        label="Apellido paterno"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="infringer_lastname"
        label="Apellido materno"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        name="infringer_ubigeo"
        label="Distrito"
        rules={[{ required: false }]}
      >

{ payload.authority_id != null &&  <SelectAjax 
          
                keyvalue={"ubigeo"}
                label={"name"}
                url={"/utils/locations?authority_id=" + payload.authority_id}
                placeholder={'Distrito'}
              />}

      </Form.Item> */}

      <Form.Item
        name="infringer_address"
        label="Dirección del infractor"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item
        name="infringement_reference"
        label="Referencia"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="infringer_card"
        label="Tarjeta de Id. Vehicular"
        rules={[{ required: false }]}
      >
        <Input />
      </Form.Item> */}
    </React.Fragment>
  );
};

const Form16 = ({ pid, onSuccess, visible }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmmiting] = useState(false);
  const [video, setVideo] = useState({});
  const [type, changeType] = useState("fined");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [fines, setFines] = useState([]);
  const [finesNa, setFinesNa] = useState([]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [dogImg, setImg] = useState(null);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        dogImg,
        croppedAreaPixels,
        rotation
      );

      setCroppedImage(croppedImage);

      return croppedImage;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, dogImg, rotation]);

  // const snap = {...video.snaps[0]};

  const fetchVideo = async () => {
    setSubmmiting(true);
    const response = await Axios.get("/videos/" + pid);

    const vid = response.data.data;
    setVideo(vid);

    form.setFieldsValue({
      infringement_address: vid.address,
      infringer_plate: vid.category?.display_plate == 1 ?  vid.vehicle_plate : null,
    });

    setSubmmiting(false);
  };

  const onObigeoLoaded = () => {
    form.setFieldsValue({
      infringement_ubigeo: video?.ubigeo?.ubigeo,
    });
  }

  const updatePlate = async (value) => {
    try {
      const response = await Axios.patch("/videos/" + video.id, {
        vehicle_plate: value,
      });

      if (response.data) {
        message.success("Placa actualizada");
      }
    } catch (error) {
      message.error("No se actualizó placa");
    }
  };

  const updateSession = (api) => {
    const { payload } = Session.get();
    payload.api_token = api;
    Session.setPayload(payload);
    setAuthHeader(payload.api_token);
  };

  const onFinish = async (values) => {
    let image = await showCroppedImage();

    const params = {
      ...values,
      image: image,
      video_id: video.id,
    };

    if (type == "fined") {
      params.response_id = values.response_id_cascade[1];
    }

    setSubmmiting(true);

    form.scrollToField("type");

    Axios.post("/fine", params)
      .then((response) => {
        const { data } = response;
        setSubmmiting(false);

        if (data && data.success === true) {
          updateSession(data.api_token);

          onSuccess(data.message, video.id);
        } else {
          updateSession(data.api_token);

          Modal.error({
            title: "Error de atención",
            destroyOnClose: true,
            maskClosable: false,
            content:
              data.message || "Error al generar atención contacte con soporte",
          });
        }
      })
      .catch((errno) => {
        Modal.error({
          title: "Error de atención",
          destroyOnClose: true,
          maskClosable: false,
          content: errno.response.data?.message ?? errno.message,
        });

        setSubmmiting(false);
      });
  };

  useEffect(() => {
    if (video.snaps) {
      const snp = { ...video.snaps[0] };
      setImg(snp.url_cdn);
    }
  }, [video]);

  useEffect(() => {
    if (visible === true) {
      fetchVideo();
    }
  }, [visible]);

  const selectFineType = (value, e) => {
     form.setFieldsValue({ description: e[1].description });
    // form.setFieldsValue({ response_id: e[1].id });
  };

  const selectFineTypeNa = (value, e) => {
    form.setFieldsValue({ description: e.data.description });
  };

  const getFineTypes = async () => {
    const response = await Axios.get("/infringement-codes?fine_type=fined");

    // Objeto para almacenar los grupos
    const groupedData = {};

    // Iterar sobre cada elemento del array
    response.data.data.forEach((item) => {
      const law = item.name;

      // Verificar si el grupo ya existe, si no, crearlo
      if (!(law in groupedData)) {
        groupedData[law] = {
          id: item.id,
          law: 'Tipos',
          children: [],
          name: item.name,
        };

      } 
        item.name = item.description.substring(0, 80);
        groupedData[law].children.push(item);
      
    });

    // Convertir el objeto en un array de grupos
    const groupedArray = Object.values(groupedData);
    setFines(groupedArray);

    const responseNa = await Axios.get(
      "/infringement-codes?fine_type=attended"
    );
    const options = responseNa.data.data.map((a) => ({
      label: `${a.name}: ${a.description}`,
      value: a.id,
      data: a,
    }));
    setFinesNa(options);
  };

  useEffect(() => {
    getFineTypes();
  }, []);

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        type: type,
        infringement_address: video.address,
        infringer_plate: video.vehicle_plate,
      }}
      scrollToFirstError
    >
      <Spin spinning={submitting}>
        <Form.Item
          name="type"
          label="Elige una opción"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group onChange={(e) => changeType(e.target.value)}>
            <Radio.Button value="fined">Sancionar</Radio.Button>
            <Radio.Button value="attended">No Sancionar</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {type != undefined && (
          <>
            {type == "fined" ? (
              <Form.Item
                name="response_id_cascade"
                label="Tipo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Cascader
                  showSearch
                  fieldNames={{
                    label: "name",
                    value: "id",
                    children: "children",
                  }}
                  options={fines || []}
                  style={{ width: "100%" }}
                  onChange={selectFineType}
                  placeholder="Selecciona"
                />
              </Form.Item>
            ) : (
              <Form.Item
                name="response_id"
                label="Tipo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onSelect={selectFineTypeNa}
                  defaultActiveFirstOption
                  options={finesNa}
                />
              </Form.Item>
            )}

         {video.category?.display_plate == 1 &&   <Form.Item
  name="infringer_plate"
  label="Placa"
  rules={[
    {
      required: type === "fined",
      message: "La placa es obligatoria.",
    },
    {
      pattern: type === "fined" ? /^([A-Z]{3}\d{4}|[A-Z]{2}\d{3}[A-Z])$/i : undefined,
      message: "La placa debe tener 3 letras seguidas de 4 números o 2 letras seguidas de 3 números y 1 letra al final.",
    },
  ]}
  hasFeedback
>
  <Input onBlur={(e) => updatePlate(e.target.value)} />
</Form.Item>
}
            <Form.Item
              name="description"
              label="Descripción"
              rules={[
                {
                  required: true,
                },
              ]}
              hasFeedback
            >
              <Input.TextArea />
            </Form.Item>
            {/* <Form.Item
              name="infringement_ubigeo"
              label="Distrito"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              { video.authority_id != null &&  <SelectAjax 
                onLoaded={onObigeoLoaded}
                keyvalue={"ubigeo"}
                label={"name"}
                url={"/utils/locations?authority_id=" + video.authority_id + "&include="+video?.ubigeo?.id}
                placeholder={'Distrito'}
              />}
            </Form.Item> */}
          </>
        )}

        {type == "fined" && (
          <div>
            <Form.Item
              name="infringement_address"
              label={
                <span>
                  Dirección &nbsp;
                  <Tooltip title="Dirección donde se produjo el incidente">
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="additional_information"
              label="Información adicional"
              rules={[{ required: false }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item
              name="comments_vipper"
              label="Comentario Vipero"
              rules={[{ required: false }]}
            >
             {video.comments ?? 'Sin comentarios'}
            </Form.Item>

            {/* <Form.Item
              name="correlative"
              label="Nº Multa"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item> */}

            <InfringerForm />

            <Row className="row-crop">
              <Col sm={12}>
                {video.snaps?.length > 0 ? (
                  <div className="snaps-slider">
                    {video.snaps.map((snap, key) => {
                      // eslint-disable-next-line jsx-a11y/alt-text
                      return (
                        <div key={key} className="img-snap">
                          <img
                            onClick={() => setImg(snap.url_cdn)}
                            src={snap.url_cdn}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <Empty description={"No hay fotos"} />
                )}

                <div className={"crop-wrapper"}>
                  <Cropper
                    image={dogImg}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={16 / 9}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={(z) => setZoom(z)}
                  />
                </div>
              </Col>

              <Col sm={12}>
                <Form.Item label="Zoom">
                  <Slider
                    value={zoom}
                    min={1}
                    max={3.5}
                    step={0.1}
                    onChange={(zoom) => setZoom(zoom)}
                  />
                </Form.Item>

                <Form.Item label="Rotación">
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    onChange={(rotation) => setRotation(rotation)}
                  />
                </Form.Item>

                <Button
                  style={{ float: "right", width: 290 }}
                  onClick={showCroppedImage}
                  type="dashed"
                >
                  Vista previa
                </Button>
                <br />
                <img className="img-cropped" src={croppedImage} />
              </Col>
            </Row>

            <Form.Item
              name="agreement"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject("Debes confirmar el envío"),
                },
              ]}
              {...tailFormItemLayout}
            >
              <Checkbox>He verificado que la información sea correcta</Checkbox>
            </Form.Item>
          </div>
        )}

        {type != undefined && (
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={submitting} htmlType="submit">
              Registrar
            </Button>
          </Form.Item>
        )}
      </Spin>
    </Form>
  );
};

export default Form16;
